import styled from 'styled-components';

export const Box = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding: 64px;
  gap: 24px;
  border-radius: 32px;
  background-color: ${(props) => props.theme.colors.neutral[200]};
  overflow: hidden;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    gap: 16px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    padding: 24px;
  }
`;

export const Info = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 24px;
  text-align: center;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    flex-direction: column;
    gap: 4px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    gap: 16px;
  }
`;

export const Item = styled.div`
  display: flex;
  justify-content: center;
  align-self: center;
  align-items: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  color: ${(props) => props.theme.colors.grey[700]};

  svg {
    margin-right: 4px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    width: 100%;
    display: flex;
    align-items: center;
    &:first-child {
      align-items: flex-start;
      svg {
        margin-top: 4px;
      }
    }
    &:not(:first-child) {
      flex-wrap: wrap;
    }
  }

  a {
    transition: all 0.3s ease;
    &:hover {
      text-decoration: underline;
    }
  }
`;

export const IconWrapper = styled.span`
  display: flex;
  justify-content: center;
`;
