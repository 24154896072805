import styled from 'styled-components';

export const Wrapper = styled.div`
  background-color: ${(props) => props.theme.colors.neutral.default};
  border-bottom: 1px solid ${(props) => props.theme.colors.grey.light};
  padding: 12px;
`;

export const InnerWrapper = styled.section`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  column-gap: 24px;
  row-gap: 8px;
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  text-align: center;
  .icon {
    margin-right: 4px;
  }
  a {
    &:hover {
      text-decoration: underline;
    }
  }
  .expand-label {
    display: none;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    flex-direction: column;
  }
`;

export const Title = styled.strong`
  font-weight: 700;
  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    font-weight: 500;
    .expand-label {
      color: ${({ theme }) => theme.colors.grey[800]};
      font-weight: 700;
      display: inline-flex;
      text-decoration: underline;
      align-items: center;
      margin-left: 4px;
      gap: 4px;
      svg {
        width: 16px;
        height: 16px;
        transition: transform 0.3s ease-in-out;
        transform: rotate(${({ minimized }) => (minimized ? 0 : '-180')}deg);
      }
    }
  }
`;
