import * as Sentry from '@sentry/nextjs';
import { notFound } from 'next/navigation';

export async function getAtlasClient({ client, preview = false }) {
  const res = preview
    ? await fetch(
        `${process.env.NEXT_PUBLIC_CMS_URL}/api/platform-clients/${client}/atlas?preview=true}`,
        {
          cache: 'no-store',
        },
      )
    : await fetch(`${process.env.NEXT_PUBLIC_CMS_URL}/api/platform-clients/${client}/atlas`, {
        next: { revalidate: 60 },
      });

  if (res?.status === 404) {
    notFound();
  }

  if (!res.ok) {
    Sentry.captureException(res);
    throw new Error('Failed to fetch data');
  }

  const data = await res.json();

  return data.data.attributes;
}
export async function getAtlasMainPage() {
  const res = await fetch(`${process.env.NEXT_PUBLIC_CMS_URL}/api/atlas-main-page/getPopulated`, {
    next: { revalidate: 60 },
  });

  if (res?.status === 404) {
    notFound();
  }

  if (!res.ok) {
    Sentry.captureException(res);
    throw new Error('Failed to fetch data');
  }

  const data = await res.json();

  return { ...data.data.attributes };
}
