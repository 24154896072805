import styled, { css } from 'styled-components';

import { Container } from '@/components/ui/flexbox';

import { Button as UiButton } from '@swordhealth/ui-corporate';

export const StyledContainer = styled(Container)`
  max-width: 1672px;
  z-index: 1;
  position: relative;
  display: flex;
  flex-direction:column;
  gap: var(--ui-section-wrap-gap-sm);
   @media (min-width: ${(props) => props.theme.breakpoints.min_md}px) {
    gap: var(--ui-section-wrap-gap-md);
  }

  @media (min-width: ${(props) => props.theme.breakpoints.min_lg}px) { {
     gap: var(--ui-section-wrap-gap-lg);   
  }
`;

export const Wrapper = styled.div`
  position: relative;
  aspect-ratio: 2/1;
  margin: auto;
  border-radius: 40px;
  overflow: hidden;

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    border-radius: 16px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_xs}px) {
    aspect-ratio: 4/5;
  }
`;

export const VideoContainer = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  pointer-events: none;
`;

export const Content = styled.div`
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  background: ${(props) => props.theme.colors.grey.dark}80;
  opacity: 0;
  transition: all 120ms ease-out;
  width: 100%;
  top: 0;
  left: 0;
  padding: 24px;

  &:hover {
    opacity: 1;
  }

  ${({ $touch }) =>
    $touch &&
    css`
      opacity: 1;
    `};
`;

export const TextContainer = styled.div`
  max-width: 360px;
  margin: auto;
  text-align: center;
`;

export const TitleWrapper = styled.div`
  color: ${(props) => props.theme.colors.white};
  padding-bottom: 24px;
`;

export const Button = styled(UiButton)`
  background-color: transparent;
  border-color: ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.white};
  align-items: center;
  display: inline-flex;
  gap: 10px;

  &:hover {
    background-color: ${(props) => props.theme.colors.white};
    color: ${(props) => props.theme.colors.grey.dark};
  }
`;
