import { Heading, RichText } from '@swordhealth/ui-corporate';
import { Container } from '@/components/ui/flexbox';
import { MarkdownRichText } from '@/app/components/shared';

import { Box, IconWrapper, Info, Item } from './styles';

import Section from '@/components/ui/Section';
import Phone from '@/public/assets/icons/ic_phone.svg';
import Envelope from '@/public/assets/icons/ic_mail.svg';

const FaqBanner = ({ content, contact }) => {
  if (!content || !contact || !content.active) return null;

  const { title } = content;
  const { phone, email, label } = contact;
  return (
    <Section lastColored>
      <Container small>
        <Box>
          <Heading as="h6" size="xl" weight="bold">
            <RichText gradientBoldText>
              <MarkdownRichText elements={['a', 'bold', 'strong', 'b']}>{title}</MarkdownRichText>
            </RichText>
          </Heading>
          {(phone || email) && (
            <Info>
              {phone && (
                <Item>
                  <IconWrapper>
                    <Phone />
                  </IconWrapper>
                  <a href={`tel:${phone.href.replace(/\s/g, '')}`}>{phone.label}</a> {label}
                </Item>
              )}
              {email && (
                <Item>
                  <Envelope />
                  <a href={`mailto:${email.href}`}>{email.label}</a>
                </Item>
              )}
            </Info>
          )}
        </Box>
      </Container>
    </Section>
  );
};
export default FaqBanner;
